<template>
  <b-container
    fluid
    class="mb-5"
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Cluster</label>
            <v-select
              v-model="selectedCluster"
              :options="filterClusters"
              placeholder="Select Cluster"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter By Distribution</label>
            <v-select
              v-model="selectedDistribution"
              :options="filterDistributions"
              placeholder="Select Distribution"
              :disabled="selectedCluster.length === 0"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter By Area</label>
            <v-select
              v-model="selectedArea"
              :options="filterAreas"
              placeholder="Select Area"
              :disabled="selectedDistribution.length === 0"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter By Dealer</label>
            <v-select
              v-model="selectedDealer"
              :options="filterDealers"
              placeholder="Select Dealer"
              :disabled="selectedArea.length === 0"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter By Product Category</label>
            <v-select
              v-model="selectedCategory"
              :options="filterCategories"
              placeholder="Select Product Category"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter By Product</label>
            <v-select
              v-model="selectedProduct"
              :options="filterProducts"
              placeholder="Select Product"
              :disabled="selectedCategory.length === 0 || products.length === 0"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter By Year</label>
            <v-select
              v-model="selectedYear"
              :options="filterYears"
              placeholder="Select Year"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter By Month</label>
            <v-select
              v-model="selectedMonth"
              :options="filterMonths"
              placeholder="Select Month"
              :disabled="selectedYear.length === 0"
            />
          </b-form-group>
        </b-col>
        <json-excel
          :fields="excel_fields"
          :data="excel_data"
          type="csv"
          class="btn btn-success m-2 ml-3"
          name="inventory-dealers-report.xls"
        >
          Download as excel <i class="ri-download-line" />
        </json-excel>
        <b-col cols="12">
          <b-table
            ref="table"
            hover
            responsive
            class="mt-2"
            :per-page="tableSettings.perPage"
            :current-page="tableSettings.currentPage"
            :items="myProvider"
            :fields="tableSettings.fields"
            :sort-by.sync="tableSettings.sortBy"
            :sort-desc.sync="tableSettings.sortDesc"
            :sort-direction="tableSettings.sortDirection"
            :filter="tableSettings.filter"
            :filter-included-fields="tableSettings.filterOn"
            show-empty
          >
            <template #cell(dcp)="data">
              {{ `${data.item.first_name} ${data.item.last_name}` }}
            </template>
            <template #cell(total_move_out)="data">
              {{ data.item.total_moveout }}
            </template>
          </b-table>
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableSettings.perPage"
                  :options="tableSettings.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableSettings.currentPage"
                :total-rows="tableSettings.totalRows"
                :per-page="tableSettings.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { ListService, ReportService } from '@/services'
import formatter from '@/mixins/formatter'
import JsonExcel from 'vue-json-excel'

export default {
  name: 'MarketingOfficerReportSample',
  middleware: ['auth', 'marketing-officer'],
  metaInfo () {
    return {
      title: 'Report'
    }
  },
  components: {
    JsonExcel
  },
  mixins: [formatter],
  data () {
    return {
      isBusy: true,
      editMode: false,
      clusters: [],
      selectedCluster: [],
      distributions: [],
      selectedDistribution: [],
      areas: [],
      selectedArea: [],
      dealers: [],
      selectedDealer: [],
      categories: [],
      selectedCategory: [],
      products: [],
      selectedProduct: [],
      years: [],
      selectedYear: [],
      months: [],
      selectedMonth: [],
      excel_data: [],
      filter: {
        cluster: null,
        distribution: null,
        area: null,
        dealer: null,
        categories: null,
        products: null,
        year: null,
        month: null,
        from_date: null,
        end_date: null
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          { key: 'product_name', sortable: true },
          { key: 'dealer_name', sortable: true },
          { key: 'month', sortable: true, formatter: this.changeMonth },
          { key: 'year', sortable: true },
          { key: 'beginning', sortable: true },
          { key: 'booking', sortable: true },
          { key: 'ending', sortable: true },
          { key: 'total_move_out', sortable: true, label: 'Total Moveout' }
        ]
      },
      excel_fields: {
        Product: 'product_name',
        'Product Category': 'product_category_name',
        DCP: 'dcp',
        Dealers: 'dealer_name',
        Area: 'area_name',
        Distribution: 'distribution_name',
        Clusters: 'cluster_name',
        Year: 'year',
        Month: {
          field: 'month',
          callback: value => {
            return this.changeMonth(value)
          }
        },
        'Beginning Inventory': 'beginning',
        'Dealer Inventory': 'booking',
        'Ending Inventory': 'ending',
        'Total Moveout': 'total_moveout'
      }
    }
  },

  computed: {
    modalTitle () {
      return this.editMode ? 'Edit Area' : 'Add Area'
    },
    filterClusters () {
      return [{ value: 'All', label: 'All' }].concat(this.clusters)
    },
    filterDistributions () {
      return [{ value: 'All', label: 'All' }].concat(this.distributions)
    },
    filterAreas () {
      return [{ value: 'All', label: 'All' }].concat(this.areas)
    },
    filterDealers () {
      return [{ value: 'All', label: 'All' }].concat(this.dealers)
    },
    filterCategories () {
      return [{ value: 'All', label: 'All' }].concat(this.categories)
    },
    filterProducts () {
      return [{ value: 'All', label: 'All' }].concat(this.products)
    },
    filterYears () {
      return [{ value: 'All', label: 'All' }].concat(this.years)
    },
    filterMonths () {
      return [{ value: 'All', label: 'All' }].concat(this.months)
    }
  },

  watch: {
    'selectedCluster' ({ value }) {
      this.getDistributions(value)
      this.filter = { ...this.filter, cluster: value, distribution: null, area: null, dealer: null }
      this.$refs.table.refresh()
    },
    'selectedDistribution' ({ value }) {
      this.getArea(value)
      this.filter = { ...this.filter, distribution: value, area: null, dealer: null }
      this.$refs.table.refresh()
    },
    'selectedArea' ({ value }) {
      this.filter = { ...this.filter, area: value, dealer: null }
      this.getDealers(value)
      this.$refs.table.refresh()
    },
    'selectedDealer' ({ value }) {
      this.filter = { ...this.filter, dealer: value }
      this.$refs.table.refresh()
    },
    'selectedCategory' ({ value }) {
      this.getProducts(value)
      this.filter = { ...this.filter, categories: value, products: null }
      this.$refs.table.refresh()
    },
    'selectedProduct' ({ value }) {
      this.filter = { ...this.filter, products: value }
      this.$refs.table.refresh()
    },
    'selectedYear' ({ value }) {
      this.getMonths(value)
    },
    'selectedMonth' ({ value }) {
      this.filter = { ...this.filter, month: value }
      this.$refs.table.refresh()
    }
  },

  mounted () {
    core.index()
    this.getClusters()
    this.getCategories()
    this.getYears()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const x = this.filter
        const cluster = x.cluster !== null ? x.cluster : ''
        const distribution = x.distribution !== null ? x.distribution : ''
        const area = x.area !== null ? x.area : ''
        const dealer = x.dealer !== null ? x.dealer : ''
        const categories = x.categories !== null ? x.categories : ''
        const products = x.products !== null ? x.products : ''
        const month = x.month !== null ? x.month : ''
        const year = x.year !== null ? x.year : ''
        const fromDate = x.end_month !== null ? x.from_date : ''
        const endDate = x.end_month !== null ? x.end_date : ''
        const anotherQuery = `cluster_id=${cluster}&distribution_id=${distribution}&area_id=${area}&dealer_id=${dealer}&category_id=${categories}&products=${products}&month=${month}&year=${year}&from_date=${fromDate}&end_date=${endDate}`

        const { data } = await ReportService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&${anotherQuery}`)
        this.tableSettings.totalRows = data.total_rows
        this.excel_data = data.items
        return data.items
      } catch {
        return []
      }
    },

    async getClusters (id) {
      await ListService.getClusters().then(({ data }) => {
        this.clusters = data.map(({ id, cluster_name }) => {
          return { value: id, label: cluster_name }
        })
      })
    },

    async getDistributions (id) {
      await ListService.getDistributions(`cluster_id=${id}`).then(
        ({ data }) => {
          this.distributions = data.map(({ id, distribution_name }) => {
            return { value: id, label: distribution_name }
          })
        }
      )
    },

    async getArea (id) {
      await ListService.getAreas(`distribution_id=${id}`).then(({ data }) => {
        this.areas = data.map(({ id, area_name }) => {
          return { value: id, label: area_name }
        })
      })
    },

    async getDealers (id) {
      await ListService.getDealers(`area_id=${id}&cluster_id=${this.filter.cluster}&distribution_id=${this.filter.distribution}`).then(({ data }) => {
        this.dealers = data.map(({ id, dealer_name }) => {
          return { value: id, label: dealer_name }
        })
      })
    },

    async getCategories (id) {
      await ListService.getProductCategories().then(({ data }) => {
        this.categories = data.map(({ id, product_category_name }) => {
          return { value: id, label: product_category_name }
        })
      })
    },

    async getProducts (id) {
      await ListService.getProducts(`product_category_id=${id}`).then(({ data }) => {
        this.products = data.map(({ id, product_name }) => {
          return { value: id, label: product_name }
        })
      })
    },

    async getYears () {
      await ListService.getYear().then(({ data }) => {
        this.years = data.map(({ year }) => {
          return { value: year, label: year }
        })
      })
    },

    async getMonths (id) {
      await ListService.getMonth(`year=${id}`).then(({ data }) => {
        this.months = data.map(({ series }) => {
          return { value: series, label: this.changeMonth(series) }
        })
      })
    },

    onDistributionFilterChange () {
      this.$refs.table.refresh()
    }
  }
}
</script>
